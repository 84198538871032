import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { InputField } from '../../components/InputField'
import { CheckBoxButton } from '../../components/CheckBoxButton'
import { SharedButton } from '../../components/Button'
import { errorAlert, successAlert } from '../../components/Alert'
import { create_rolls } from '../../api_services/Apiservices'
import { useNavigate } from 'react-router-dom'

export const CreateRole = ({ setLoder }) => {
    const navigate = useNavigate();
    const [fdataOld, setFdataOld] = useState({
        "role": "",
        "User_Profile_Module": false,
        "Training_Module": false,
        "Inventory_Module": false,
        "Availability_Module": false,
        "Qualification_Module": false,
        "Reporting_Module": false,
        "Dashboard": false,
        "Role_Administration": false,
        "Gear_Administration": false
    });

    const moduleListOld = [
        { id: 8, name: 'Role Administration', value: "Role_Administration" },
        { id: 3, name: 'Qualification Administration', value: "Qualification_Module" },
        { id: 5, name: 'User Profile Administration', value: "User_Profile_Module" },
        { id: 4, name: 'Event Administration', value: "Training_Module" },
        { id: 1, name: 'Inventory Administration', value: "Inventory_Module" },
        { id: 9, name: 'Gear Administration', value: "Gear_Administration" },
        { id: 2, name: 'Availability', value: "Availability_Module" },
        { id: 6, name: 'Reporting', value: "Reporting_Module" },
        { id: 7, name: 'Dashboard', value: "Dashboard" },
    ]

    const [fdata, setFdata] = useState({
        "role": "",
        "Dashboard": false,
        "Role_and_Qualifications_Administration": false,
        "User_Profile_Administration": false,
        "Messaging": false,
        "Event_Administration": false,
        "Gear_and_Apparatus_Administration": false,
        "Reports": false,
        "Custom_Field": false,
    });


    const moduleList = [
        { id: 1, name: 'Dashboard', value: "Dashboard" },
        { id: 2, name: 'Role and Qualifications Administration', value: "Role_and_Qualifications_Administration" },
        { id: 3, name: 'User Profile Administration', value: "User_Profile_Administration" },
        { id: 4, name: 'Messaging', value: "Messaging" },
        { id: 5, name: 'Event Administration', value: "Event_Administration" },
        { id: 6, name: 'Gear and Apparatus Administration', value: "Gear_and_Apparatus_Administration" },
        { id: 7, name: 'Reports', value: "Reports" },
        { id: 8, name: 'Custom Field', value: "Custom_Field" },
    ]
    const createHandler = async () => {
        if (fdata && !fdata.role) { errorAlert("Please Enter Role"); return null; }
        setLoder(true)
        const datas = {
            "role": fdata.role,
            "Dashboard": fdata.Dashboard,
            "Role_and_Qualifications_Administration": fdata.Role_and_Qualifications_Administration,
            "User_Profile_Administration": fdata.User_Profile_Administration,
            "Messaging": fdata.Messaging,
            "Event_Administration": fdata.Event_Administration,
            "Gear_and_Apparatus_Administration": fdata.Gear_and_Apparatus_Administration,
            "Reports": fdata.Reports,
            "Custom_Field": fdata.Custom_Field,
        }
        const resp = await create_rolls(datas);
        if (resp && resp.success) {
            setLoder(false);
            successAlert(resp.message);
            navigate("/roleadminstratorlist");
        }
        setLoder(false);
    }

    const checkedHandler = (data) => {
        const name = data.data.value;
        const isChecked = data.isChecked;
        setFdata((pre) => ({ ...pre, [name]: isChecked }));
    }
    return (
        <>
            <div className='CreateRoleForm'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <InputField required={true} FormLabel='Role Name' FormPlaceHolder='Enter Role Name' value={fdata.roll} onChange={(e) => setFdata((pre) => ({ ...pre, 'role': e.target.value }))} />
                        </Col>
                    </Row>
                    <h6>Select Module</h6>
                    <div className='RoleModule'>
                        <Row>
                            {moduleList && moduleList.map((e, i) => (
                                <Col md={3} key={i} >
                                    <CheckBoxButton BtnLabel={e.name} fulldata={e} BtnClass={'checked-btn'} onClick={checkedHandler} type={'check'} />
                                </Col>
                            ))}
                        </Row>
                    </div>
                    <Row className='mt-3'>
                        <Col md={4}>
                            <SharedButton BtnLabel={'Create'} type={'button'} onClick={createHandler} BtnVariant={'primary'} BtnClass={'w-100'} />
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <span className='error'>Note: Fields marked with an asterisk (*) are mandatory and must be filled out before submitting the form .</span>
                    </Row>
                </Container>
            </div>
        </>
    )
}
