import { useEffect, useState } from "react";
import { Button, Stack, Table } from "react-bootstrap";
import UpcomingEventTableModal from "./UpcomingEventTableModal";
import { getTraningAll_API } from "../../api_services/Apiservices";
import { sortEventByDate } from "../../helper/Helper";
import moment from "moment";
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineQuestionCircle } from "react-icons/ai";

export const UpcomingEventTable = ({ setLoder }) => {

    const [myd, setMyd] = useState(JSON.parse(localStorage.getItem("mydata")));
    const [showModal, setShowModal] = useState(false);
    const [trData, setTrData] = useState([]);
    const [trainingData, setTrainingData] = useState([]);
    const [modalData, setModalData] = useState();

    const getdata = async () => {
        setLoder(true);
        const resp = await getTraningAll_API();
        if (resp && resp.success) {
            const preData = resp.data;
            const pre1Data = sortEventByDate(preData, true);
            const currentDate = moment().startOf('day').unix();
            const filteredData = pre1Data.filter(item => item.date >= currentDate);
            setTrData(filteredData);
            setTrainingData(filteredData);
            setLoder(false);
        }
        setLoder(false);
    }

    useEffect(() => { getdata(); }, [])

    return (
        <div className="MainTable subMain">
            <Table className="table" style={{ margin: '0px', padding: "0px" }} responsive>
                <thead>
                    <tr>
                        <th colSpan={4} className="border-0"></th>
                        <th colSpan={2} className="text-center">Attendees</th>
                    </tr>
                    <tr>
                        <th>EVENT TYPE</th>
                        <th>EVENT NAME</th>
                        <th>EVENT DATE</th>
                        <th>Start TIME</th>
                        <th>INTEREST</th>
                        <th>ACTUAL</th>
                        <th>ACTION</th>
                    </tr>
                </thead>
                <tbody>
                    {trData.map((account, index) => (
                        <tr key={index}>
                            <td>{account.event_type_id && account.event_type_id.event_name}</td>
                            <td>{account.name}</td>
                            <td>{moment.unix(account.date).format("MM-DD-YYYY")}</td>
                            <td>{moment.unix(account.start_time).format(myd.time_formate)}</td>
                            <td>
                                <Stack direction='horizontal' gap={2} style={{ justifyContent: "center" }}>
                                    {account.planning_to_attend} <AiOutlineCheckCircle className='check_icon' />
                                    {account.cannot_attend}<AiOutlineCloseCircle className='close_icon' />
                                    {account.no_response} <AiOutlineQuestionCircle className='question_icon' />
                                </Stack>

                            </td>
                            <td style={{ textAlign: "center" }}>{account.completed != 0 ? account.completed : "n/a"}</td>
                            <td>     <Button variant="success" size="sm" className="me-2"
                                onClick={() => { setShowModal(true); setModalData(account) }}
                            >Detail
                            </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {/* <div className="subMain">
                <Table className="table table-hover" style={{ margin: '0px', padding: "0px" }} responsive>
                 
                </Table>
            </div> */}
            <UpcomingEventTableModal show={showModal} modalData={modalData} handleClose={() => { setShowModal(false); setModalData() }} setLoder={setLoder} />
        </div>
    );
} 