import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap'
import { FaSortAlphaDownAlt, FaSortAlphaUp } from 'react-icons/fa';
import { getAttendees_API } from '../../api_services/Apiservices';

export default function UpcomingEventTableModal({ handleClose, show, modalData, setLoder }) {

    const [myd] = useState(JSON.parse(localStorage.getItem("mydata")));
    const [attendees, setAttendees] = useState([]);
    const [order, setOrder] = useState(true);
    const [event_id,setEvent_id] = useState();
    const [inData, setInData] = useState(
        {
            "id": "", "name": "", "description": "", "duration": "", "date": "", "time": "", "event_type": "", "location": "",
            "capacity": "", "notes": "", "qualification_id": "", "qf_name": "", "customFiled": [],
        });

    const [data, setData] = useState([])

    const setDataHandler = (data) => {
        let pre = { ...inData };
        if (data) {
            pre.id = data?._id;
            pre.name = data?.name;
            pre.description = data?.description;
            pre.duration = data?.duration ? parseFloat(data.duration).toFixed(2) : "";
            pre.date = data?.date ? moment.unix(data.date).format("MM-DD-YYYY") : ""; //data?.date;
            pre.time = data?.start_time ? moment.unix(data.start_time).format(myd?.time_formate) : "";
            pre.event_type = data?.event_type_id?.event_name ? data?.event_type_id.event_name : "";
            pre.location = data?.location;
            pre.capacity = data?.capacity;
            pre.notes = data?.notes;
            pre.qf_name = data?.qualification_id?.name ? data?.qualification_id.name : "";
            pre.customFiled = data?.customFiled;
        }
        setInData(pre);
        if (data?._id) {
            getAttendeesList(data._id);
        }
    }

    



    const getAttendeesList = async (id) => {
        let myOrd = "asc";
        if (order) {
            myOrd = "asc"
        } else {
            myOrd = "desc";
        }
        if (id) {
            setLoder(true);
            const resp = await getAttendees_API(id, myOrd);
            if (resp && resp.success) {
                setLoder(false);
                // const atd = resp.data.map(e => ({
                //     "id": e._id,
                //     "name": e.user_name,
                //     "status": e.current_status === "select" ? "" : e.current_status,
                //     "update_by": e.creator_id,
                //     "training_id": e.training_id,
                //     "credit_duration": e.credit_duration,
                //     "trainingDuration": inData.duration
                // }));
                // setData(atd);
                setAttendees(resp.data);
            }
            setLoder(false);
        }
    }

    useEffect(() => {
        getAttendeesList(event_id);
    }, [order])

    useEffect(() => {
        if(modalData && modalData._id){
            setEvent_id(modalData._id);
        }
        setDataHandler(modalData);
    }, [modalData])



    return (
        <Modal size='xl' show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Row style={{ padding: '10px' }} >
                    <Col md={12}>
                        {/* <Container>                       */}
                        <Row className='mb-2 m-1' style={{ border: "1px solid #e6e6e6", borderRadius: '10px' }}>
                            <Col md={6} className='mb-2'>
                                <h6>Event Type</h6>  <p>{inData && inData.event_type}</p>  <hr />
                            </Col>
                            <Col md={6} className='mb-2'>
                                <h6>Event Name</h6> <p>{inData && inData.name}</p> <hr />
                            </Col>
                            <Col md={12}>
                                <h6>Event Description</h6> <p>{inData.description}</p><hr />
                            </Col>
                            <Col md={6} className='mb-2'>
                                <h6>Event Date</h6><p>{inData.date}</p><hr />
                            </Col>
                            <Col md={6} className='mb-2'>
                                <h6>Start Time</h6> <p>{inData.time}</p> <hr />
                            </Col>
                            <Col md={6} className='mb-2'>
                                <h6>Duration</h6><p>{inData.duration}</p><hr />
                            </Col>
                            <Col md={6} className='mb-2'>
                                <h6>Capacity</h6><p>{inData?.capacity}</p> <hr />
                            </Col>

                            <Col md={6} className='mb-2'>
                                <h6>Location</h6> <p>{inData?.location}</p> <hr />
                            </Col>
                            <Col md={6} className='mb-2'>
                                <h6>Qualification</h6><p>{inData?.qf_name}</p><hr />
                            </Col>
                            <Col md={12} className='mb-2'>
                                <h6>Notes</h6> <p>{inData?.notes}</p><hr />
                            </Col>
                            {inData?.customFiled && inData.customFiled.map((e, i) => (
                                <Col md={6} className='mb-3' key={i}>
                                    {e.type === "text" ?
                                        <> <h6>{e.title}</h6> <p>{e.value}</p> </>
                                        :
                                        <> <h6>{e.title}</h6>
                                            <p>
                                                {e.options && e.value
                                                    ? e.options.find((d) => d.value === e.value)?.name || "No match"
                                                    : "No options"}
                                            </p>
                                        </>
                                    }
                                    <hr />
                                </Col>
                            ))}
                        </Row>
                   
                    <Row>
                        <Col className={attendees.length > 0 ? "md-12 text-center" : "md-12 text-center"} ><h6 style={{ textTransform: "uppercase", fontWeight: "bold" }}>Attendees List</h6></Col>
                        {attendees.length > 0 &&
                            <Col md={12} className='mt-1'>
                         
                                <div className='MainTable'>
                                    <Table responsive className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center" }}>Status</th>
                                                <th onClick={() => setOrder(!order)} style={{ cursor: "pointer", textAlign: "center" }}> {order ? <span> <FaSortAlphaUp style={{ color: "#198754" }} /> </span> : <span> <FaSortAlphaDownAlt style={{ color: "#198754" }} /> </span>} Name</th>
                                                <th style={{ textAlign: "center" }}>Credit Hours</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {attendees.map((e, i) => (
                                                <tr key={i}>
                                                    <td style={{ textAlign: "center", textTransform: "capitalize" }}>
                                                        {(e.current_status === "planning_to_attend" || e.current_status === "Planning to Attend") && "Planning to Attend"}
                                                        {(e.current_status === "cannot_attend" || e.current_status === "Will Not Attend") && "Will Not Attend"}
                                                        {(e.current_status === "did_not_attend" || e.current_status === "Did Not Attend") && "Did Not Attend"}
                                                        {(e.current_status === "completed" || e.current_status === "Completed") && "Completed"}
                                                        {(e.current_status === "removed" || e.current_status === "Removed") && "Removed"}
                                                        {(e.current_status === "cancelled" || e.current_status === "Cancelled") && "Cancelled"}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>{e.user_name}</td>
                                                    <td style={{ textAlign: "center" }}>{e.credit_duration ? parseFloat(e.credit_duration).toFixed(2) : ""}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>

                        }
                    </Row>
                    </Col>

                    {/* </Container> */}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
