import { Row, Col, Container } from "react-bootstrap";
import { PoSidebar } from "../PO_Sidebar";
import { Headings } from "../../components/Headings";
import { UpcomingEventTable } from "./UpcomingEventTable";
import { ApparatusStatusTable } from "./ApparatusStatusTable";
import { PendingQualificationTable } from "./PendingQualificationExpireTable";
import { UnavailableStaffTable } from "./UnavailableStaffTable";
import { useState } from "react";
import { Loader } from "../../components/Loader";

export const Dashboard = () => {

    const [loder, setLoder] = useState(false);


    const pendingQualification = [
        { name: 'Jackson', qualification: 'CPR I', expiration: '10/15/2024' },
        { name: 'Jackson', qualification: 'CPR I', expiration: '10/15/2024' }
    ];
    const unavailableStaff = [
        { name: 'Test, User', mobile: "999999999", email: 'coder@gmail.com', role: "role-123", position: "position-12" },
        { name: 'Test, User', mobile: "999999999", email: 'coder@gmail.com', role: "role-123", position: "position-12" },
        { name: 'Test, User', mobile: "999999999", email: 'coder@gmail.com', role: "role-123", position: "position-12" },
        { name: 'Test, User', mobile: "999999999", email: 'coder@gmail.com', role: "role-123", position: "position-12" },
    ];
    return (
        <>
            <Loader show={loder} />
            <div className="dashboard">
                <Container fluid>
                    <Row>
                        <Col md={3}>
                            <PoSidebar />
                        </Col>
                        <Col md={9}>
                            <Headings MainHeading={'Dashboard'} />
                            <hr />
                            <Row >
                                <Col md={12} className="mt-2 mb-4">
                                    <div className="myCustomHeader mb-3">
                                        <h5>Unavailable Staff Today</h5>
                                    </div>
                                    <UnavailableStaffTable data={unavailableStaff} />
                                </Col>
                                <Col md={12} className="mt-4 mb-4">
                                    <div className="myCustomHeader mb-3">
                                        <h5>Apparatus Status</h5>
                                    </div>
                                    <ApparatusStatusTable setLoder={setLoder} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className="mt-4 mb-4">
                                    <div className="myCustomHeader mb-3">
                                        <h5>Upcoming Event Information</h5>
                                    </div>
                                    <UpcomingEventTable setLoder={setLoder} />
                                </Col>

                                <Col md={12} className="mt-4 mb-4">
                                    <div className="myCustomHeader mb-3">
                                        <h5>Pending Qualifications Expirations </h5>
                                    </div>
                                    <PendingQualificationTable data={pendingQualification} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}