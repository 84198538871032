import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, } from 'react-bootstrap';
import { InputField } from '../../components/InputField';
import { SharedButton } from '../../components/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorAlert, successAlert } from '../../components/Alert';
import { deleteRoll_API, updateRoll_API } from '../../api_services/Apiservices';
import { TbEdit } from "react-icons/tb";
import { CheckBoxButton } from '../../components/CheckBoxButton';
import { NewCheckBoxButton } from '../../components/NewCheckBoxButton';
import Swal from 'sweetalert2';



export default function RoleFullDetails({ setLoder }) {
    const [isedit, setIsedit] = useState(false);
    const [maindata, setMaindata] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    // const [moduleList, setModuleList] = useState([
    //     { id: 8, name: 'Role Administration', value: "Role_Administration", ischek: false },
    //     { id: 3, name: 'Qualification Administration', value: "Qualification_Module", ischek: false },
    //     { id: 5, name: 'User Profile Administration', value: "User_Profile_Module", ischek: false },
    //     { id: 4, name: 'Event Administration', value: "Training_Module", ischek: false },
    //     { id: 1, name: 'Inventory Administration', value: "Inventory_Module", ischek: false },
    //     { id: 9, name: 'Gear Administration', value: "Gear_Administration", ischek: false },
    //     { id: 2, name: 'Availability', value: "Availability_Module", ischek: false },      
    //     { id: 6, name: 'Reporting', value: "Reporting_Module", ischek: false },
    //     { id: 7, name: 'Dashboard', value: "Dashboard", ischek: false },     
    // ])


    const [moduleList, setModuleList] = useState([
        { id: 1, name: 'Dashboard', value: "Dashboard", ischek: false },
        { id: 2, name: 'Role and Qualifications Administration', value: "Role_and_Qualifications_Administration", ischek: false },
        { id: 3, name: 'User Profile Administration', value: "User_Profile_Administration", ischek: false },
        { id: 4, name: 'Messaging', value: "Messaging", ischek: false },
        { id: 5, name: 'Event Administration', value: "Event_Administration", ischek: false },
        { id: 6, name: 'Gear and Apparatus Administration', value: "Gear_and_Apparatus_Administration", ischek: false },
        { id: 7, name: 'Reports', value: "Reports", ischek: false },
        { id: 8, name: 'Custom Field', value: "Custom_Field", ischek: false },
    ])


    const [fdata, setFdata] = useState({ "id": '', "role": "", });


    useEffect(() => {
        if (location && location.state && location.state.data) {
            const data = location.state.data;
            setFdata({ "id": data._id, "role": data.role, });
            setModuleList(
                [
                    { id: 1, name: 'Dashboard', value: "Dashboard", ischek: data.Dashboard },
                    { id: 2, name: 'Role and Qualifications Administration', value: "Role_and_Qualifications_Administration", ischek: data.Role_and_Qualifications_Administration },
                    { id: 3, name: 'User Profile Administration', value: "User_Profile_Administration", ischek: data.User_Profile_Administration },
                    { id: 4, name: 'Messaging', value: "Messaging", ischek: data.Messaging },
                    { id: 5, name: 'Event Administration', value: "Event_Administration", ischek: data.Event_Administration },
                    { id: 6, name: 'Gear and Apparatus Administration', value: "Gear_and_Apparatus_Administration", ischek: data.Gear_and_Apparatus_Administration },
                    { id: 7, name: 'Reports', value: "Reports", ischek: data.Reports },
                    { id: 8, name: 'Custom Field', value: "Custom_Field", ischek: data.Custom_Field },

                    // { id: 8, name: 'Role Administration', value: "Role_Administration", ischek: data.Role_Administration },
                    // { id: 3, name: 'Qualification Administration', value: "Qualification_Module", ischek: data.Qualification_Module },
                    // { id: 5, name: 'User Profile Administration', value: "User_Profile_Module", ischek: data.User_Profile_Module },
                    // { id: 4, name: 'Event Administration', value: "Training_Module", ischek: data.Training_Module },
                    // { id: 1, name: 'Inventory Administration', value: "Inventory_Module", ischek: data.Inventory_Module },
                    // { id: 9, name: 'Gear Administration', value: "Gear_Administration", ischek: data.Gear_Administration },
                    // { id: 2, name: 'Availability', value: "Availability_Module", ischek: data.Availability_Module },
                    // { id: 6, name: 'Reporting', value: "Reporting_Module", ischek: data.Reporting_Module },
                    // { id: 7, name: 'Dashboard', value: "Dashboard", ischek: data.Dashboard },
                ]
            )
            const array = [];
            if (data && data.Dashboard) { array.push('Dashboard') }
            if (data && data.Role_and_Qualifications_Administration) { array.push('Role and Qualifications Administration') }
            if (data && data.User_Profile_Administration) { array.push('User Profile Administration') }
            if (data && data.Messaging) { array.push('Messaging') }
            if (data && data.Event_Administration) { array.push('Event Administration') }
            if (data && data.Gear_and_Apparatus_Administration) { array.push('Gear and Apparatus Administration') }
            if (data && data.Reports) { array.push('Reports') }
            if (data && data.Custom_Field) { array.push('Custom Field') }

            data.modules = array;
            if (data) { setMaindata(data); }
        }
    }, [location])


    const updateHandler = async () => {
        if (fdata && !fdata.role) { errorAlert("Please Enter Role"); return null; }
        Swal.fire({
            title: "Are you sure?",
            text: "This Role has been modified. Save changes?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoder(true);
                const dash = moduleList.find((e) => e.value === 'Dashboard').ischek;
                const role_qul = moduleList.find((e) => e.value === 'Role_and_Qualifications_Administration').ischek;
                const user_pro = moduleList.find((e) => e.value === 'User_Profile_Administration').ischek;
                const mess = moduleList.find((e) => e.value === 'Messaging').ischek;
                const event_adm = moduleList.find((e) => e.value === 'Event_Administration').ischek;
                const gear_apt = moduleList.find((e) => e.value === 'Gear_and_Apparatus_Administration').ischek;
                const rep = moduleList.find((e) => e.value === 'Reports').ischek;
                const cus_fi = moduleList.find((e) => e.value === 'Custom_Field').ischek;
                const datas = {
                    "id": fdata.id,
                    "role": fdata.role,
                    "Dashboard": dash,
                    "Role_and_Qualifications_Administration": role_qul,
                    "User_Profile_Administration": user_pro,
                    "Messaging": mess,
                    "Event_Administration": event_adm,
                    "Gear_and_Apparatus_Administration": gear_apt,
                    "Reports": rep,
                    "Custom_Field": cus_fi,
                }
                const resp = await updateRoll_API(datas);
                if (resp && resp.success) {
                    setLoder(false);
                    successAlert(resp.message);
                    navigate("/roleadminstratorlist");
                }

            }
        });






        setLoder(false);
    }

    const deleteHandler = (id) => {
        if (!id) { errorAlert("Something wrong"); return; }
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const resp = await deleteRoll_API({ "id": id });
                if (resp && resp.success) {
                    setLoder(false);
                    Swal.fire({
                        title: "Deleted!",
                        text: "Your Data has been deleted.",
                        icon: "success"
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            navigate("/roleadminstratorlist");
                        }
                    })
                }
            }
        });
        // 
    }

    const cartClick = () => {

    }


    const cancelHandler = () => {
        Swal.fire({
            title: "Changes have been made",
            text: "Are you sure you want to exit with no changes?",
            icon: "question"
        }).then((result) => {
            if (result.isConfirmed) { setIsedit(false); }
        });
    }



    return (
        <>

            <div className='RoleAdminstrator'>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            {isedit ?
                                <div className='CreateRoleForm'>
                                    <Container>
                                        <Row style={{ justifyContent: 'end' }}>
                                            <Col md={1}>
                                                <Button variant="danger" size="sm"
                                                    onClick={cancelHandler}>Cancel
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <InputField required={true} FormLabel='Role Name' FormPlaceHolder='Enter Role Name' value={fdata.role} onChange={(e) => setFdata((pre) => ({ ...pre, 'role': e.target.value }))} />
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <h6>Select Module</h6>
                                            <div className='RoleModule'>
                                                <Row>
                                                    <NewCheckBoxButton option={moduleList} setModuleList={setModuleList} BtnClass={'checked-btn'} type={'check'} />

                                                </Row>
                                            </div>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col md={4}>
                                                <SharedButton BtnLabel={'Update'} type={'button'} onClick={updateHandler} BtnVariant={'primary'} BtnClass={'w-100'} />
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <span className='error'>Note: Fields marked with an asterisk (*) are mandatory and must be filled out before submitting the form .</span>
                                        </Row>
                                    </Container>
                                </div>
                                :
                                <div className='CreateAccountForm UseDetailPages'>
                                    <Container>
                                        <Row style={{ justifyContent: 'end' }}>
                                            <Col md={2} style={{ textAlign: "center" }}>
                                                <Button variant="success" size="sm"
                                                    onClick={() => setIsedit(true)} style={{
                                                        fontWeight: '500',
                                                        marginRight: '1rem'
                                                    }}><TbEdit />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <h4>Role Name</h4>
                                                <p> {maindata && maindata.role}  </p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <h6>Select Module</h6>
                                            <div className='RoleModule '>
                                                <Row>
                                                    {maindata && (maindata.modules).map((e, i) => (
                                                        <Col md={3} key={i} >
                                                            <CheckBoxButton BtnLabel={e} fulldata={e} BtnClass={'checked-btn checked mb-2 color_white'} type={'check'} onClick={cartClick} />
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                        </Row>

                                    </Container>
                                </div>
                            }

                        </Col>
                    </Row>

                </Container>
            </div>

        </>
    )
}
