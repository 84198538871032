import { Table } from "react-bootstrap";

export const UnavailableStaffTable = ({ data }) => {
    return (
        <div className="MainTable subMain">
            <Table className="table table-hover" style={{ margin: '0px', padding: "0px" }} responsive>
                <thead>
                    <tr>
                        <th style={{ width: "20%", textAlign: 'left' }}>NAME</th>
                        <th style={{ width: "20%", textAlign: 'left' }}>Email</th>
                        <th style={{ width: "20%", textAlign: 'left' }}>MOBILE NO</th>
                        <th style={{ width: "20%", textAlign: 'left' }}>ROLE</th>
                        <th style={{ width: "20%", textAlign: 'left' }}>POSITION</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((items, index) => (
                        <tr key={index}>
                            <td style={{ textTransform: 'capitalize', textAlign: 'left' }}>{items.name}</td>
                            <td style={{ textTransform: 'none', textAlign: 'left' }}>{items.email}</td>
                            <td style={{ width: "20%", textAlign: 'left' }}>{items.mobile}</td>
                            <td style={{ textTransform: 'capitalize', textAlign: 'left' }}>{items.role}</td>
                            <td style={{ textTransform: 'capitalize', textAlign: 'left' }}>{items.position}</td>
                        </tr>
                    ))}
                </tbody>

            </Table>
        </div>
    );
};
